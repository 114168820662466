import { useContext, useEffect, useState } from 'react';

const useFetch = (url, wallet) => {

    const [data, setData] = useState(null);
    const [isPending,setIsPending] = useState(true); 
    const [error,setError] = useState(null);
    
    useEffect(() => {
        fetch(url + wallet)
           .then(res => {
            if (!res.ok) {
              throw('Couldnt fetch data')   
            }
            return res.json()
          })
           .then(data => {
            setData(data.rewards);
            setIsPending(false);
            setError(null);
          }).catch(err => {
            setError(err.message);
            setIsPending(false);
          })
      }, [url, wallet])

    return {data, isPending, error}  
}

export default useFetch