import Navbar from './Navbar';
import Home from './Home';
import useDeviceDetect from './UseDeviceDetect';
import AppContext from './Appcontext';
import SecurityPolicy from './SecurityPolicy';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Rewards from './Rewards';

function App() {
  
  const {isMobile} = useDeviceDetect();
  const userSettings = {
    isMobile,
  };

  return (
    <AppContext.Provider value={userSettings}>
      <Router>
        <div className='App'>
          <Navbar/>
          <div className='content'>
            <Switch>
              <Route exact path='/'>
                <Home/>
              </Route>
              <Route path='/rewards'>
                <Rewards/>
              </Route>
              <Route path='/policy'>
                <SecurityPolicy/>
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
