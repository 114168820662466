// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*default_color:#f1356d;*/
/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 1%;
  display: flex;
  align-items: center;
  max-width: 800px; 
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #098b55;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a.mobile {
  display: block;
}
.navbar a.dektop{
  display: flex;
}
.navbar a:hover {
  color: #098b55;
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

/* blog previews / list */
.rewards-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.rewards-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.rewards-preview h2 {
  font-size: 20px;
  color: #098b55;
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA,yBAAyB;AACzB,gBAAgB;AAChB;EACE,SAAS;EACT,wBAAwB;EACxB,WAAW;AACb;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,gCAAgC;AAClC;AACA;EACE,cAAc;AAChB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf;;AAEA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,cAAc;EACd,gCAAgC;AAClC;AACA;EACE,uCAAuC;AACzC;AACA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');\n\n/*default_color:#f1356d;*/\n/* base styles */\n* {\n  margin: 0;\n  font-family: \"Quicksand\";\n  color: #333;\n}\n.navbar {\n  padding: 1%;\n  display: flex;\n  align-items: center;\n  max-width: 800px; \n  margin: 0 auto;\n  border-bottom: 1px solid #f2f2f2;\n}\n.navbar h1 {\n  color: #098b55;\n}\n.navbar .links {\n  margin-left: auto;\n}\n.navbar a {\n  margin-left: 16px;\n  text-decoration: none;\n  padding: 6px;\n}\n.navbar a.mobile {\n  display: block;\n}\n.navbar a.dektop{\n  display: flex;\n}\n.navbar a:hover {\n  color: #098b55;\n}\n\n.content {\n  max-width: 600px;\n  margin: 40px auto;\n  padding: 20px;\n}\n\n/* blog previews / list */\n.rewards-preview {\n  padding: 10px 16px;\n  margin: 20px 0;\n  border-bottom: 1px solid #fafafa;\n}\n.rewards-preview:hover {\n  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);\n}\n.rewards-preview h2 {\n  font-size: 20px;\n  color: #098b55;\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
