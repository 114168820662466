import { useContext, useEffect, useState } from 'react';
import AppContext from "./Appcontext";
import RewardsList from './RewardsList';
import useFetch from './useFetch';

const Rewards = () => {

    const [wallet, setWallet] = useState(null);
        const {data: rewards, isPending, error} = useFetch('https://stakeapi.easytoken.me/rewardsbyepoch/', wallet)
    
        const handleClick = () => {
            //setWallet('8eUZjUjXNQG5wbmsydsNKatRxHMmWAebeDR6JuYUfGJi')
            setWallet('000000000000000000000000000000000000000000000')
          }
    
        return (  
            <div className="rewards">
                <h4>{wallet}</h4>
                <button style={{
                        color:"white",
                        backgroundColor:"#098b55",
                        borderRadius:'8px',
                    }}
                    onClick={() => handleClick()}>Connect wallet</button>
              { isPending && <div>Loading...</div> }
              { error && <div> {error} </div> }
              { rewards && <RewardsList rewards={rewards} title = 'Under construction'/> }
            </div>    
        );
}
 
export default Rewards;