const RewardsList = ({rewards , title, handleDelete}) => {

    return ( 
        <div className="Rewards-list">
            <h2>{title}</h2>
            {rewards.map((reward)=>(
            <div className='rewards-preview' key={reward.epoch}>
              <h2>{ reward.epoch }</h2>
              <p>Delegated stake { reward.delegatedStakeSol } </p>
            </div>
          ))}
        </div>
     );
}
 
export default RewardsList;