import { useContext } from 'react';
import AppContext from "./Appcontext";

const Navbar = () => {

    const userSettings = useContext(AppContext);
    let style = userSettings.isMobile ? "mobile" : "desktop"

    return ( 
        <nav className="navbar">
            <h1>Easytoken</h1>
            <div className="links">
                <a className={style} href="/">Home</a>
                {/*<a className={style} href="/about">About us</a>*/}
                <a className={style} href="/rewards">Rewards</a>
                <a className={style} href="/policy">Security policy</a>
                {/*<a className={style} style={{
                    color:"white",
                    backgroundColor:"#098b55",
                    borderRadius:'8px',
                }} href="/connect">Connect wallet</a>*/}
            </div>
        </nav>
     );
}
 
export default Navbar;