import { useContext, useEffect, useState } from 'react';
import AppContext from "./Appcontext";

const Home = () => {

    const userSettings = useContext(AppContext);

    return (  
        <div className="home">
            <h2>Subscribe to <a href="https://t.me/easytoken">@easytoken</a></h2>
        </div>

    );
}
 
export default Home;