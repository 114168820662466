const SecurityPolicy = () => {
    return (
        <div className="securitypolicy">
        <h2>High performance hardware</h2>
        <br/>
        <h4>Threadripper processors and high-speed SSD's allow us to show best productivity possible</h4>
        <br/>
        <h2>Up-to-date software</h2>
        <br/>
        <h4>We meticulously update the server's operating system and applications with the most recent security patches. This timely application of updates ensures that all known vulnerabilities are promptly addressed.</h4>
        <br/>
        <h2>Firewall</h2>
        <br/>
        <h4>Our firewall undergoes rigorous configuration to meticulously allow only the essential ports and protocols. All other access attempts are resolutely blocked, effectively minimizing the exposure of our systems to potential threats.</h4>    
        <br/>
        <h2>System monitoring</h2>
        <br/>
        <h4>We leverage Prometheus and Grafana to vigilantly monitor the servers and nodes for anomalies. These advanced monitoring and alerting systems promptly detect unusual activity, enabling us to swiftly investigate and mitigate potential threats</h4>
        </div>
      );
}
 
export default SecurityPolicy;
